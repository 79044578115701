<template>
  <div>
    <VisualHero
      :image="computedImg"
      :gradient="!!geo"
      width="full"
      overflow="auto"
      background-color="bg-gray-index"
      preload
    >
      <template slot="title">
        <strong>La chiave giusta per il tuo immobile</strong>
      </template>
      <template slot="description">
        <p class="subtitle">
          Ci siamo rinnovati, ma ti seguiamo sempre passo dopo passo.
          <br aria-hidden="true" />
          Cerca o vendi il tuo immobile con noi.
        </p>
      </template>
      <ClientOnly slot="body">
        <HomeSearch />
        <template #placeholder>
          <HomeSearchSkeleton />
        </template>
      </ClientOnly>
      <div slot="footer">
        <p class="mb-8">
          Vuoi vendere casa?<br aria-hidden="true" />Valutiamo insieme il tuo
          immobile
        </p>
        <UcrsButton
          type="primary"
          name="&lid=scopri la promozione|homepage"
          role="link"
          @click="$router.push(sellPageUrl)"
        >
          Vendi il tuo Immobile
        </UcrsButton>
      </div>
    </VisualHero>
    <div class="pt-32 pb-16 lg:py-32 container">
      <section class="flex flex-col gap-24">
        <div v-if="swiperLoading" class="flex flex-col gap-24">
          <div class="flex">
            <HomeCardSkeleton class="px-10 flex-1" />
            <HomeCardSkeleton class="px-10 flex-1" />
            <HomeCardSkeleton class="px-10 flex-1" />
          </div>
          <div class="flex">
            <HomeCardSkeleton class="px-10 flex-1" />
            <HomeCardSkeleton class="px-10 flex-1" />
            <HomeCardSkeleton class="px-10 flex-1" />
          </div>
        </div>
        <div v-else>
          <ClientOnly>
            <h2 v-if="computedSearches.length" class="mb-10 section-headline">
              Ricerche recenti
            </h2>
            <Swiper :options="swiperOptions" @init="swiperLoading = false">
              <HomeCard
                v-for="(item, index) in computedSearches"
                :key="`Searches_${index}`"
                class="swiper-slide"
                icon="search"
                layout="row"
                icon-color="text-blue"
                link-text="Vai alla ricerca"
                :aria-label="item.title"
                :link-href="item.route"
              >
                <p slot="heading" v-dompurify-html="item.title"></p>
                <p slot="description" v-dompurify-html="item.description"></p>
              </HomeCard>
              <HomeCard
                v-for="(item, index) in infosHomeFirst.length -
                computedSearches.length"
                :key="`HomeCardFirst_${index}`"
                class="swiper-slide"
                :icon="infosHomeFirst[index].icon"
                layout="row"
                icon-color="text-blue"
                :link-text="infosHomeFirst[index].link?.text"
                :link-href="infosHomeFirst[index].link?.href"
              >
                <p
                  slot="heading"
                  v-dompurify-html="infosHomeFirst[index].title"
                ></p>
                <p
                  slot="description"
                  v-dompurify-html="infosHomeFirst[index].description"
                ></p>
              </HomeCard>
            </Swiper>
          </ClientOnly>
        </div>
        <div>
          <ClientOnly>
            <h2 v-if="properties.length" class="mb-10 section-headline">
              Annunci visti di recente
            </h2>
            <Swiper
              :options="secondRowSwiperOptions"
              dots-position="outside"
              @init="swiperLoading = false"
            >
              <PropertyCard
                v-for="(item, index) in properties"
                :key="`RecentProperty_${index}`"
                class="swiper-slide max-w-112"
                :equal-height="true"
                :show-add="false"
                :property="item"
                show-title
                show-virtual-tour
              />
              <template v-if="!properties.length">
                <HomeCard
                  v-for="(item, index) in infosHomeSecond"
                  :key="`HomeCardSecond_${index}`"
                  class="swiper-slide"
                  :icon="item.icon"
                  layout="row"
                  icon-color="text-blue"
                  :link-text="item.link?.text"
                  :link-href="item.link?.href"
                >
                  <p slot="heading" v-dompurify-html="item.title"></p>
                  <p slot="description" v-dompurify-html="item.description"></p>
                </HomeCard>
              </template>
              <template v-else>
                <div
                  v-for="(item, index) in infosHomeSecond.length -
                  (properties.length - 1)"
                  :key="`HomePlaceholder_${index}`"
                  class="swiper-slide flex flex-col max-w-112"
                >
                  <NuxtImg
                    class="object-cover h-80 lg:h-65"
                    :src="
                      infosHomeSecond[index].image
                        ? infosHomeSecond[index].image
                        : `/assets/img/ucrs-placeholder.png`
                    "
                    alt=""
                    format="webp"
                    loading="lazy"
                    width="280"
                    height="162"
                  />
                  <div
                    class="flex flex-col flex-1 gap-3 px-4 py-6 border-1 border-t-0 border-gray-lightest rounded-b"
                  >
                    <p
                      v-dompurify-html="infosHomeSecond[index].title"
                      class="paragraph"
                    ></p>
                    <p
                      v-dompurify-html="infosHomeSecond[index].description"
                      :title="infosHomeSecond[index].description"
                      class="truncate"
                    ></p>
                  </div>
                </div>
              </template>
            </Swiper>
          </ClientOnly>
        </div>
      </section>
      <section class="mt-32">
        <h2 class="section-title mb-12">Immobili in evidenza</h2>
        <HomeCarousel />
      </section>
    </div>
    <section class="bg-white-dark py-24">
      <div class="container">
        <template v-if="swiperLoading">
          <div class="flex gap-20">
            <OSkeleton
              root-class="w-1/3 h-144"
              item-class="h-full"
              :rounded="false"
            />
            <OSkeleton
              root-class="w-1/3 h-144"
              item-class="h-full"
              :rounded="false"
            />
            <OSkeleton
              root-class="w-1/3 h-144"
              item-class="h-full"
              :rounded="false"
            />
          </div>
        </template>
        <template v-else>
          <ClientOnly>
            <Swiper :options="swiperOptions" @init="swiperLoading = false">
              <div v-for="(card, i) in cards" :key="i" class="swiper-slide">
                <TipsCard
                  :item="card"
                  :img="`/assets/img/${card.imgSrc}`"
                  :gradient="false"
                >
                </TipsCard>
              </div>
            </Swiper>
          </ClientOnly>
        </template>
      </div>
    </section>
    <section class="container py-32">
      <h2 class="font-bold text-3xl mb-8">
        Case e annunci immobiliari in Italia
      </h2>
      <p class="text-lg mb-16">
        UniCredit RE Services è la nuova società di intermediazione immobiliare
        che ti accompagna passo dopo passo in ogni fase della compravendita del
        tuo immobile con semplicità e trasparenza, affida il tuo immobile alla
        nostra rete di consulenti immobiliari.
      </p>
      <GeoSitemap type="home" />
    </section>
  </div>
</template>

<script>
// Libs
import { defineAsyncComponent } from 'vue'
import { mapGetters, mapState } from 'vuex'

// Static assets
import {
  promotionCards,
  infoSearchFirstRow,
  infoSearchSecondRow,
} from '~/assets/js/homepage'
import { mapQuery } from '~/assets/js/listing'
import SellGeo from '~/assets/json/sell-geo.json'
// import HeroImgs from '~/assets/js/images/hero'

// Components
import UcrsButton from '~/components/UcrsButton.vue'
import HomeCard from '~/components/HomeCard.vue'
import HomeCardSkeleton from '~/components/HomeCardSkeleton.vue'
import PropertyCard from '~/components/PropertyCard.vue'
import Swiper from '~/components/Swiper.vue'
import TipsCard from '~/components/TipsCard.vue'
import VisualHero from '~/components/VisualHero.vue'
import GeoSitemap from '~/components/GeoSitemap.vue'
import HomeSearchSkeleton from '~/components/HomeSearchSkeleton.vue'

const HomeCarousel = defineAsyncComponent({
  loader: () => import('~/components/HomeCarousel.vue'),
})
const HomeSearch = defineAsyncComponent({
  loader: () => import('~/components/HomeSearch.vue'),
  // Loading component with delay 0 is needed to show the Skeleton as soon
  // as the import for the component starts (needed in slow networks to avoid CLS)
  loadingComponent: HomeSearchSkeleton,
  delay: 0,
})

const MonthsImagesBasePath = '/assets/img/visual/months'
const GeoImagesBasePath = '/assets/img/visual/geo'
// TODO: Rendiamo statica l'immagine fino a giugno come da ticket
// [USCSD-1362](https://desk.immobiliarefull.com/projects/USCSD/queues/custom/466/USCSD-1362)
// const CurrentMonth = new Date()
//   .toLocaleString('en-US', { month: 'long' })
//   .toLowerCase()
// const HeroImg = HeroImgs[CurrentMonth]
// const MonthImgPath =
//   HeroImg && `${MonthsImagesBasePath}/${HeroImg.name}.${HeroImg.ext}`
const MonthImgPath = `${MonthsImagesBasePath}/ucrs-visual-january.png`

export default {
  components: {
    UcrsButton,
    GeoSitemap,
    HomeCard,
    HomeCardSkeleton,
    HomeSearchSkeleton,
    HomeCarousel,
    PropertyCard,
    Swiper,
    TipsCard,
    VisualHero,
    HomeSearch,
  },

  data() {
    return {
      cards: promotionCards,
      infosHomeFirst: infoSearchFirstRow,
      infosHomeSecond: infoSearchSecondRow,
      search: true,
      swiperLoading: true,
      swiperOptions: {
        spaceBetween: 20,
        slidesPerView: 1.4,
        breakpoints: {
          480: { slidesPerView: 2.2 },
          1024: { slidesPerView: 3 },
        },
      },
    }
  },

  head() {
    return {
      script: [{ type: 'application/ld+json', json: this.organization }],
    }
  },

  computed: {
    // TODO: keep state needed by this page only
    ...mapState('search', ['geo']),
    ...mapGetters({
      searches: 'searches/get',
      properties: 'properties/get',
    }),

    organization() {
      const img = this.$img('/assets/img/logo/ucrs-logo.svg')
      const logo = this.$config.cdnAssetsEnabled
        ? img
        : 'https://www.unicreditres.it' +
          this.$img('/assets/img/logo/ucrs-logo.svg')

      return {
        '@context': 'http://schema.org',
        '@type': 'Organization',
        name: 'UniCredit RE Services',
        description:
          'Per comprare o vendere la tua casa affidati a UniCredit RE Services: semplicità, trasparenza e professionalità in ogni fase della compravendita.',
        logo,
        url: 'https://www.unicreditres.it/',
        telephone: '800896968',
        parentOrganization: {
          name: 'UniCredit',
          legalName: 'UniCredit S.p.A.',
          url: 'https://www.unicredit.it/',
        },
      }
    },

    computedImg() {
      if (!MonthImgPath) {
        return
      }

      if (!this.geo) {
        return MonthImgPath
      }

      const provinceId = this.geo.provincia

      const computedImg = `${GeoImagesBasePath}/ucrs-visual-geo-${provinceId}.jpg`

      return computedImg
    },

    secondRowSwiperOptions() {
      const { swiperOptions, properties } = this

      if (!properties.length) {
        return swiperOptions
      }

      return {
        ...swiperOptions,
        breakpoints: {
          ...swiperOptions.breakpoints,
          1024: {
            ...swiperOptions.breakpoints[1024],
            slidesPerView: 4,
          },
        },
      }
    },

    computedSearches() {
      const { searches } = this
      const { formatNumber } = this.$utilities

      const computedSearches = []

      for (const item of searches) {
        const { query } = item.route
        const {
          priceFrom,
          priceTo,
          roomsFrom,
          roomsTo,
          surfaceFrom,
          surfaceTo,
        } = mapQuery(query)

        const priceFromMsg = priceFrom && `min ${formatNumber(priceFrom)} €`
        const priceToMsg = priceTo && `max ${formatNumber(priceTo)} €`
        const roomsFromMsg =
          roomsFrom && `min ${roomsFrom} ${roomsFrom > 1 ? 'locali' : 'locale'}`
        const roomsToMsg =
          roomsTo && `max ${roomsTo} ${roomsTo > 1 ? 'locali' : 'locale'}`
        const surfaceFromMsg = surfaceFrom && `min ${surfaceFrom} m<sup>2</sup>`
        const surfaceToMsg = surfaceTo && `max ${surfaceTo} m<sup>2</sup>`
        const description = [
          roomsFromMsg,
          roomsToMsg,
          priceFromMsg,
          priceToMsg,
          surfaceFromMsg,
          surfaceToMsg,
        ]
          .filter((it) => it)
          .join(' | ')

        computedSearches.push({
          title: item.title,
          route: item.route,
          description,
        })
      }

      return computedSearches
    },

    sellPageUrl() {
      const { geo } = this
      if (!geo?.comuneKeyurl) {
        return '/vendo'
      }

      const citySlug = geo.comuneKeyurl.toLowerCase()

      const vendoPageExists = SellGeo.cities.some((it) => it.slug === citySlug)

      if (vendoPageExists) {
        return `/vendo/vendere-casa-${citySlug}`
      }

      return '/vendo'
    },
  },
}
</script>
